import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import SupportRoute from "./los-routes/SupportRoute";
import BankProfileUpdates from "../pages/partner/my-profile/profile-updates/BankProfileUpdates";
import HuntingRoute from "./hunting-routes/HuntingRoutes";
import ProfileRoutes from "./customer-application-routes/profile-update/ProfileUpdate";
import Invoice_Index from "../pages/partner/invoice/invoice-index";
import Invoice_form from "../pages/partner/invoice/invoice-form";
import Invoice_view from "../pages/partner/invoice/invoice-view";
import KFSSecondScreen from "../pages/customer-application/kfs/kfsSecondScreen";
import KFSThirdScreen from "../pages/customer-application/kfs/kfsThirdScreen";
import UdyamHomeScreen from "../pages/customer-application/udyam/home/Home";
import UdyamHelp from "../pages/customer-application/udyam/help/Help&Support";
import PaymentHelpSection from "../pages/customer-application/udyam/help/PaymentHelp";
import CreditHelpSection from "../pages/customer-application/udyam/help/CreditHelp";
import ProfileChangeSection from "../pages/customer-application/udyam/help/ProfileChangeHelp";
import QueriesHelpSection from "../pages/customer-application/udyam/help/QueriesHelp";
import MobileInput from "../pages/customer-application/udyam/login/MobileInput";
import LoanSummaryScreen from "../pages/customer-application/udyam/credits/LoanSummaryScreen";
import LoanDetailScreen from "../pages/customer-application/udyam/credits/LoanDetailScreen";
import TrackApplicationPWA from "../pages/customer-application/udyam/credits/trackapplicationpwa";
import CustomerProfile from "../pages/customer-application/udyam/profile/customerProfileScreen";
import RepaymentSchedule from "../pages/customer-application/udyam/credits/repaymentScheduleScreen";
import LoanScreen from "../pages/customer-application/udyam/credits/LoanScreen";
import ICELogIn from "../pages/ice/login/logIn";
import Customers from "../pages/ice/customers/Customers";
import CustomerDetails from "../pages/ice/customer-details/CustomerDetails";
import Ice from "../pages/ice/home/Home";

const RouteGuard = lazy(
  () => import("./customer-application-routes/RouteGuard")
);
const JourneyRoutes = lazy(
  () => import("./customer-application-routes/JourneyRoutes")
);
const EnachRoutes = lazy(
  () => import("./customer-application-routes/enach/EnachRoutes")
);
const ApplicationWrapper = lazy(
  () => import("./customer-application-routes/ApplicationWrapper")
);
const PersistentDrawerLeft = lazy(
  () => import("../components/sidebar/Sidebar")
);
const PartnerRoute = lazy(() => import("./los-routes/PartnerRoute"));
const Authentication = lazy(
  () => import("../pages/authentication/Authentication")
);
const SignUpWizard = lazy(
  () => import("../pages/partner-application/wizard/SignUpWizard")
);
const ThankYou = lazy(
  () => import("../pages/partner-application/thank-you/ThankYou")
);
const RoleSelection = lazy(
  () => import("../pages/authentication/RoleSelection")
);
const DashBoard = lazy(
  () => import("../pages/central-ops/dashboard/DashBoard")
);
const Products = lazy(() => import("../pages/partner/products/Products"));
const FollowUs = lazy(() => import("../pages/partner/follow-us/FollowUs"));
const MyProfile = lazy(() => import("../pages/partner/my-profile/MyProfile"));
const CohortRoute = lazy(() => import("./los-routes/CohortRoute"));

const FofonBoarding = lazy(
  () => import("../pages/sales-application/FofonBoarding/FofonBoarding")
);
const FosonBoardingList = lazy(
  () => import("../pages/sales-application/FosonBoardingList/FosonBoardingList")
);
const LeadList = lazy(
  () => import("../pages/sales-application/LeadList/LeadList")
);
const LeadCreate = lazy(
  () => import("../pages/sales-application/AddLeadList/AddLeadList")
);
const QuanlityCheck = lazy(
  () => import("../pages/sales-application/CentralOps/QualityCheck")
);
const CentersopsLeadList = lazy(
  () => import("../pages/sales-application/CentralOps/CentralOpsLeadList")
);
const UpdateDocument = lazy(
  () => import("../pages/sales-application/UpdateLeadList/UpdateLeadList")
);
const FosQuanlityCheck = lazy(
  () => import("../pages/sales-application/LeadList/LeadListqc")
);
const LeadUpload = lazy(
  () => import("../pages/sales-application/UploadLead/UploadLead")
);
const RoasterManagement = lazy(
  () =>
    import(
      "../pages/sales-application/RoasterMangement/TaskManagement/TaskManagementList"
    )
);
const TaskAssignmentCentralsOps = lazy(
  () =>
    import(
      "../pages/sales-application/CentralOps/TaskAssignment/TaskAssignment"
    )
);
const UploadTask = lazy(
  () =>
    import("../pages/sales-application/TaskAssignment/UploadTask/UploadTask")
);
const TaskAssignmentFos = lazy(
  () => import("../pages/sales-application/TaskAssignmentFos/TaskAssignmentFos")
);
const TaskAssignmentFosList = lazy(
  () =>
    import("../pages/sales-application/TaskAssignmentFos/TaskAssignmentFosList")
);
const TaskAssignmentListView = lazy(
  () =>
    import(
      "../pages/sales-application/TaskAssignmentFos/TaskAssignmentListView"
    )
);
const TaskAssignmentUploadTaskPQ = lazy(
  () =>
    import("../pages/sales-application/TaskAssignment/UploadTask/UploadTaskPQ")
);
const TaskAssignmentForm = lazy(
  () =>
    import(
      ".././components/sales-application/TaskAssignmentForm/TaskAssignmentForm"
    )
);
const TaskAssignmentQuanlityCheck = lazy(
  () =>
    import(
      "../pages/sales-application/CentralOps/TaskAssignment/TaskAssignmentqc"
    )
);
const FosOnBooarddingEdit = lazy(
  () => import("../pages/sales-application/FofonBoarding/FofonBoardingEdit")
);
const TrackingUserRole = lazy(
  () => import("../pages/sales-application/Tracking/TrackingUserRole")
);
//const TaskAssignmentBPO = lazy(() => import("../pages/sales-application/TaskAssignmentFos/TaskAssignmentBPO"))
const DisPositionNewHLp = lazy(
  () =>
    import(
      "../components/sales-application/TaskAssignmentForm/DispositionBPONewHLP"
    )
);
const DispositionBPOExitHLP = lazy(
  () =>
    import(
      "../components/sales-application/TaskAssignmentForm/DispositionBPOExistHlp"
    )
);
const LeadListCAf = lazy(
  () => import("../pages/sales-application/LeadList/LeadListCA")
);
const Dashboardemployeebase = lazy(
  () =>
    import(
      "../pages/sales-application/DashboardEmployeebase/DashboardCentraolsCommon"
    )
);
const EditLeadListbase = lazy(
  () => import("../pages/sales-application/AddLeadList/EditLeadList")
);

const HlpCafDisposition = lazy(
  () =>
    import("../pages/partner-application/hlp-caf-disposition/HlpCafDisposition")
);
const CreateBusinessPrimeTask = lazy(
  () =>
    import(
      "../components/sales-application/TaskAssignmentForm/BusinessPrimeTask/BusinessPrimeTask"
    )
);
const BusinessPrimeFormdispostion = lazy(
  () =>
    import(
      "../components/sales-application/TaskAssignmentForm/BusinessPrimeTask/BusinessPrimeFormdispostion"
    )
);

const Router = () => (
  <BrowserRouter>
    <Suspense>
      <Routes>
        <Route
          path="/customer_update/:loanUid/:userMobile/:id*"
          element={<ApplicationWrapper component={<ProfileRoutes />} />}
        />
        <Route path="/" element={<Authentication />} />
        <Route path="/select-role" element={<RoleSelection />} />
        <Route
          path="/signup/:id"
          element={<Authentication isSignupPage={true} />}
        />
        <Route path="/signup/verify" element={<SignUpWizard />} />
        <Route
          path="/signup/thank-you"
          element={
            <ThankYou
              message={
                "Your application for onboarding the partner has been submitted successfully."
              }
              back_link={"Back to partners"}
            />
          }
        />
        <Route
          path="/dashboard"
          element={<PersistentDrawerLeft component={<DashBoard />} />}
        />
        <Route
          path="/cohort/*"
          element={<PersistentDrawerLeft component={<CohortRoute />} />}
        />
        <Route
          path="/partner/*"
          element={<PersistentDrawerLeft component={<PartnerRoute />} />}
        />
        <Route
          path="/support/*"
          element={<PersistentDrawerLeft component={<SupportRoute />} />}
        />
        <Route
          path="/:partnerSlug/utils/:customerCode/*"
          element={<ApplicationWrapper component={<RouteGuard />} />}
        />
        <Route
          path="/:partnerSlug/session/:userMobile/*"
          element={<ApplicationWrapper component={<JourneyRoutes />} />}
        />
        <Route
          path="/:customerID/*"
          element={<ApplicationWrapper component={<EnachRoutes />} />}
        />
        {/* salesapp */}
        <Route
          path="/fos/create/*"
          element={<PersistentDrawerLeft component={<FofonBoarding />} />}
        />
        <Route
          path="/lead/Edit/:id"
          element={<PersistentDrawerLeft component={<EditLeadListbase />} />}
        />
        <Route
          path="/fos/*"
          element={<PersistentDrawerLeft component={<FosonBoardingList />} />}
        />
        <Route
          path="/lead/*"
          element={<PersistentDrawerLeft component={<LeadList />} />}
        />
        <Route
          path="/lead/create*"
          element={<PersistentDrawerLeft component={<LeadCreate />} />}
        />
        <Route
          path="/fos/qc/:id"
          element={<PersistentDrawerLeft component={<QuanlityCheck />} />}
        />
        <Route
          path="/lead/qc/:id"
          element={<PersistentDrawerLeft component={<FosQuanlityCheck />} />}
        />
        <Route
          path="/fos/leadlist*"
          element={<PersistentDrawerLeft component={<CentersopsLeadList />} />}
        />
        <Route
          path="/lead/update/:id"
          element={<PersistentDrawerLeft component={<UpdateDocument />} />}
        />
        <Route
          path="/leads/upload*"
          element={<PersistentDrawerLeft component={<LeadUpload />} />}
        />
        <Route
          path="/roaster-management*"
          element={<PersistentDrawerLeft component={<RoasterManagement />} />}
        />
        <Route
          path="/taskassignment*"
          element={
            <PersistentDrawerLeft component={<TaskAssignmentCentralsOps />} />
          }
        />
        <Route
          path="/upload-task*"
          element={<PersistentDrawerLeft component={<UploadTask />} />}
        />
        <Route
          path="/task-assignment*"
          element={<PersistentDrawerLeft component={<TaskAssignmentFos />} />}
        />
        <Route
          path="/task-assignmentList*"
          element={
            <PersistentDrawerLeft component={<TaskAssignmentFosList />} />
          }
        />
        <Route
          path="/taskassignment-list*"
          element={
            <PersistentDrawerLeft component={<TaskAssignmentListView />} />
          }
        />
        <Route
          path="/nc-upload*"
          element={
            <PersistentDrawerLeft component={<TaskAssignmentUploadTaskPQ />} />
          }
        />
        <Route
          path="/task-assignment-disposition/:id*"
          element={<PersistentDrawerLeft component={<TaskAssignmentForm />} />}
        />
        <Route
          path="/taskAssignmentQuanlityCheck/:id"
          element={
            <PersistentDrawerLeft component={<TaskAssignmentQuanlityCheck />} />
          }
        />
        <Route
          path="/fos/edit/:id*"
          element={<PersistentDrawerLeft component={<FosOnBooarddingEdit />} />}
        />
        <Route
          path="/dashboard/:role/:id*"
          element={
            <PersistentDrawerLeft component={<Dashboardemployeebase />} />
          }
        />
        <Route
          path="/tracking/user-role*"
          element={<PersistentDrawerLeft component={<TrackingUserRole />} />}
        />
        {/* <Route path="/task-assignment-Bpo*" element={<PersistentDrawerLeft component={<TaskAssignmentBPO />} />} /> */}
        <Route
          path="/disposition-exit-hlp*"
          element={
            <PersistentDrawerLeft component={<DispositionBPOExitHLP />} />
          }
        />
        <Route
          path="/disposition-hlp-caf"
          element={<PersistentDrawerLeft component={<HlpCafDisposition />} />}
        />
        <Route
          path="/create-businessprime-form"
          element={
            <PersistentDrawerLeft component={<CreateBusinessPrimeTask />} />
          }
        />
        <Route
          path="/create-businessprime-disposition-form/:id"
          element={
            <PersistentDrawerLeft component={<BusinessPrimeFormdispostion />} />
          }
        />

        <Route
          path="/disposition-new-hlp*"
          element={<PersistentDrawerLeft component={<DisPositionNewHLp />} />}
        />
        <Route
          path="/leadList-caf*"
          element={<PersistentDrawerLeft component={<LeadListCAf />} />}
        />

        {/* independent routes -- Partner portal*/}
        <Route
          path="/products/"
          element={<PersistentDrawerLeft component={<Products />} />}
        />
        <Route
          path="/follow-us/"
          element={<PersistentDrawerLeft component={<FollowUs />} />}
        />
        <Route
          path="/follow-us/"
          element={<PersistentDrawerLeft component={<FollowUs />} />}
        />
        <Route
          path="/partner-profile/"
          element={<PersistentDrawerLeft component={<MyProfile />} />}
        />
        <Route
          path="/partner-profile/request_update/bank_details"
          element={<PersistentDrawerLeft component={<BankProfileUpdates />} />}
        />
        <Route path="/hunt/*" element={<HuntingRoute />} />
        <Route
          path="/invoice"
          element={<PersistentDrawerLeft component={<Invoice_Index />} />}
        />
        <Route
          path="/invoice/invoice-create"
          element={<PersistentDrawerLeft component={<Invoice_form />} />}
        />
        <Route
          path="/invoice/:id"
          element={<PersistentDrawerLeft component={<Invoice_view />} />}
        />

        {/* ICE Routes */}
        <Route path="/ice/home" element={<Ice />} />
        <Route path="/ice/" element={<ICELogIn />} />
        <Route path="/ice/customers" element={<Customers />} />
        <Route path="/ice/customer_details" element={<CustomerDetails />} />

        {/* Udyam 2.0 */}

        {/* <Route path="/arth/login" element={<MobileInput device={"web"} />} /> */}
        {/* <Route path="/arth_pwa" element={<MobileInput device={"pwa"} />} /> */}
        {/* <Route path="/loansummaryscreen" element={<LoanSummaryScreen />} /> */}
        {/* <Route path="/loanscreen" element={<LoanScreen />} /> */}
        {/* <Route path="/loandetailscreen" element={<LoanDetailScreen />} /> */}
        <Route path="/trackapplication" element={<TrackApplicationPWA />} />
        {/* <Route path="/arth/profile" element={<CustomerProfile />} /> */}
        <Route
          path="/accept_tnc/repayament_schedule/:partnerSlug/:mobile"
          element={<RepaymentSchedule />}
        />
        <Route
          path="/accept_tnc/:partnerSlug/:mobile/"
          element={<KFSSecondScreen />}
        />
        <Route
          path="/accecpt_tnc/accept/:partnerSlug/:mobile"
          element={<KFSThirdScreen />}
        />
        {/* <Route path="/arth" element={<UdyamHomeScreen />} /> */}
        {/* <Route path="/arth/help" element={<UdyamHelp />} /> */}
        {/* <Route path="/arth/help/payment" element={<PaymentHelpSection />} /> */}
        {/* <Route path="/arth/help/credit" element={<CreditHelpSection />} /> */}
        {/* <Route
          path="/arth/help/profilechange"
          element={<ProfileChangeSection />}
        /> */}
        {/* <Route path="/arth/help/queries" element={<QueriesHelpSection />} /> */}
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Router;
