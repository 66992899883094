import React from "react";
import KYC from "../KYC/CustomerKYC";
import BureauDetails from "../BureauDetails/BureauDetails";
import CustomerDemographics from "../DetailsBox/CustomerDemographics";
import "./customer-details.scss";


function CustomerDetailsTab({userData, report, activeTab, additionalData}) {
  console.log("additional one  ", additionalData)

  return (
    <div style={{flex: 1}}>
      <div class="row" style={{ paddingTop: "20px", flex: 1, display: "flex" }}>
        <div className="col-md" style={{display: "flex"}}>
          <CustomerDemographics
            heading={"Demographics"}
            demographicsData={userData?.customer_details?.Demographics}
            equifax_match={additionalData?.equifax_match}
            bigBox={true}
          />
        </div>
        <div class="col-md" style={{display: "flex"}}>
          <BureauDetails bureauData={additionalData?.bureau_details} report={report} userId={userData?.basic_details?.id} bureauHistory={additionalData?.bureau_history} activeTab={activeTab}/>
        </div>
      </div>
      
      <div class="row" style={{paddingInline: "10px", marginTop: "10px"}}>
        <KYC userData={userData}/>
      </div>
    </div>
  );
}

export default CustomerDetailsTab;
