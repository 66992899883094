import { useState } from "react";
import CustomerDetailsTab from "../CustomerDetailsTab/CustomerDetailsTab";
import "./main-details.scss";
import BusinessDetailsTab from "../BusinessDetailsTab/BusinessDetailsTab";
import CoBorrowerDetailsTab from "../CoBorrowerDetailsTab/CoBorrowerDetailsTab";

function MainDetails({userData,makerData, setBureauType, report, riskDetails, additionalData}) {
  const [activeTab, setActiveTab] = useState("customer");
  return (
    <div className="switch-btn-container">
      <div>
        <div style={{ display: "flex" }}>
          <div
            class={`col-md switch-buttons ${
              activeTab === "customer" && "switch-selected"
            }`}
            onClick={() => {setActiveTab("customer")
              setBureauType("customer")
            }}
          >
            Customer Details
          </div>
          <div
            class={`col-md switch-buttons ${
              activeTab === "business" && "switch-selected"
            }`}
            onClick={() => setActiveTab("business")}
          >
            Business Details
          </div>
          <div
            class={`col-md switch-buttons ${
              activeTab === "borrower" && "switch-selected"
            }`}
            onClick={() => {setActiveTab("borrower")
              setBureauType("borrower")
            }}
          >
            Co-Borrower Details
          </div>
        </div>
        <div style={{ paddingInline: "20px", paddingBottom: "10px" }}>
          {(() => {
            if (activeTab === "customer") {
              
              return <CustomerDetailsTab userData={userData} report={report} activeTab={activeTab} additionalData={additionalData}/>;
            } else if (activeTab === "business") {
              return <BusinessDetailsTab userData={userData} makerData={makerData} report={report} activeTab={activeTab} riskDetails={riskDetails}/>;
            } else {
              return <CoBorrowerDetailsTab userData={userData} report={report} activeTab={activeTab}/>;
            }
          })()}
        </div>
      </div>
    </div>
  );
}

export default MainDetails;