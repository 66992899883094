import { LocalStorage } from "../../utils/constants/localStorage";
import { headers, formDataHeaders, ArthAppHeaders, pwaHeaders, supportHeaders } from "../base";
import axios, { Axios, AxiosResponse } from "axios";
// import { axiosInstance as axios } from "../base";

export class ApiService {
  private BASE_URL: string = process.env.REACT_APP_BASE_URL;
  private LMS_BASE_URL: string = process.env.REACT_APP_LMS_BASE_URL;
  private BASE_URL_V2: string = process.env.REACT_APP_BASE_URL_V2;
  private UTILS_API_URL: string = process.env.REACT_APP_UTILS_API_URL;
  private UTILS_API_URL_V2: string = process.env.REACT_APP_UTILS_API_URL_V2;
  private PWA_URL: string = process.env.REACT_APP_PWA_URL;
  private RISK_URL: string = process.env.REACT_APP_RISK_URL;
  private ATHOS_URL: string = process.env.REACT_APP_ATHOS_URL;
  private ICE_URL: string = process.env.REACT_APP_ICE_URL;
  private ARTH_APP_URL: string = process.env.REACT_APP_ARTH_APP_URL;
  private SDK: string = process.env.REACT_APP_BASE_URL_NEW;
  private PWA_NEW_URL: string = process.env.REACT_APP_PWA_NEW_URL;

  public partnerSlug = localStorage.getItem(LocalStorage.PARTNER_SLUG);
  public customerCode = localStorage.getItem(LocalStorage.CUSTOMER_CODE);

  getCustomerOffer = (
    partnerSlug: string,
    customerCode: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.UTILS_API_URL_V2}/${partnerSlug}/${customerCode}/offers/check`,
      { headers: pwaHeaders() }
    );
  };

  getRepaymentDetails = (payload: any): Promise<AxiosResponse> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/fetch/repayments`,
      payload,
      { headers: pwaHeaders() }
    );
  };

  getTransactionDetails = (
    customer_code: string | null,
    pageNo,
    startDate,
    endDate,
    searchText,
    data_type
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.PWA_NEW_URL}/retrieve/paymint_txn`;
    let data = {};

    if (customer_code) {
      data["customer_code"] = customer_code;
    }
    if (pageNo) {
      data["current"] = pageNo;
    }
    if (startDate) {
      data["created_at_start"] = startDate;
    }
    if (endDate) {
      data["created_at_end"] = endDate;
    }
    if (searchText) {
      data["search"] = searchText;
    }
    if (data_type) {
      data["data_type"] = data_type;
    }
    return axios.get(`${url}`, {
      params: data,
      headers: pwaHeaders(),
    });
  };

  getSettlementDetails = (
    customer_code: string | null,
    pageNo,
    startDate,
    endDate,
    searchText
  ): Promise<AxiosResponse<any>> => {
    let url = `${this.PWA_NEW_URL}/retrieve/paymint_settlement`;
    let data = {};

    if (customer_code) {
      data["customer_code"] = customer_code;
    }
    if (pageNo) {
      data["current"] = pageNo;
    }
    if (startDate) {
      data["created_at_start"] = startDate;
    }
    if (endDate) {
      data["created_at_end"] = endDate;
    }
    if (searchText) {
      data["search"] = searchText;
    }
    return axios.get(`${url}`, {
      params: data,
      headers: pwaHeaders(),
    });
  };

  support = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.PWA_NEW_URL}/customer/support`, payload,  {
      headers: supportHeaders(),
    })
  }

  getTnCDocuments = (customerCode: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.PWA_NEW_URL}/documents/${customerCode}`, {
      headers: pwaHeaders(),
    });
  };

  checkSession = (mobile: string): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/sessions/check`,
      { sdk_user: { mobile } },
      { headers: headers() }
    );
  };

  foreclosureValidity = (payload): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.PWA_NEW_URL}/check/foreclosure_validity`,
      payload,
      { headers: pwaHeaders() }
    );
  };

  sendCustomerLocation = (
    latitude: string,
    longitude: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.PWA_URL}/customer_location`,
      { latitude, longitude, mobile: this.customerCode },
      { headers: headers() }
    );
  };

  requestOtp = (mobile: string): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL_V2}/${this.partnerSlug}/sessions/otp`,
      { sdk_user: { mobile, key: "gen_otp" } },
      { headers: headers() }
    );
  };

  verifyOtp = (mobile: string, otp: string): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL_V2}/${this.partnerSlug}/sessions/login`,
      { sdk_user: { mobile, otp } },
      { headers: headers() }
    );
  };

  getOTP = (mobile: string): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.PWA_NEW_URL}/get/pwa_otp/${mobile}`);
  };

  verifyMobileOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.PWA_NEW_URL}/verify/pwa_otp`, payload);
  };

  getCustomerProfile = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.BASE_URL}/${this.partnerSlug}/profiles/me`, {
      headers: headers(),
    });
  };
  //
  getFetchAgreement = (partnerId): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.BASE_URL}/${this.partnerSlug}/fetch/agreement/${partnerId}`, {
      headers: headers(),
    });
  };
  getLoanStatus = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.BASE_URL}/${this.partnerSlug}/loan-status`, {
      headers: headers(),
    });
  };

  updateOffer = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.UTILS_API_URL_V2}/${this.partnerSlug}/${this.customerCode}/offers/update_offer`,
      { offer: payload },
      { headers: headers() }
    );
  };

  fetchInstallmentAmount = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ATHOS_URL}/get_kfs_details`, payload, {
      headers: headers(),
    });
  };

  claimOffer = (
    payload: any,
    partnerSlug: string,
    customerCode: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.UTILS_API_URL_V2}/${partnerSlug}/${customerCode}/offers/claim`,
      { offer: payload },
      { headers: headers() }
    );
  };

  requestSurePassOtp = (
    loanUid: string,
    aadhar: number
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/aadhaar_request_otp/${loanUid}`,
      { id: aadhar },
      { headers: headers() }
    );
  };

  verifySurePassOtp = (
    loanUid: string,
    client_id: string,
    otp: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/aadhaar_verify_otp/${loanUid}`,
      { otp, client_id },
      { headers: headers() }
    );
  };

  fetchInvoidUrl = (loan_uid: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/fetch_invoid_url/${loan_uid}`,
      { headers: headers() }
    );
  };

  verifyInvoidXml = (tid: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/show_transaction_status/${tid}`,
      { headers: headers() }
    );
  };

  verifyPan = (
    pan_no: string,
    partner_code: string,
    customer_code: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${partner_code}/validate_pan`,
      { pan_no, partner_code, customer_code },
      { headers: headers() }
    );
  };

  sendImage = (sdk_user: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.BASE_URL}/${this.partnerSlug}/profiles/me`,
      { sdk_user },
      { headers: headers() }
    );
  };

  sendVideo = (formData: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.BASE_URL}/${this.partnerSlug}/profiles/me`,
      formData,
      { headers: formDataHeaders() }
    );
  };

  fetchIfscDetails = (ifscCode: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/ifsc_master/${ifscCode}`,
      { headers: headers() }
    );
  };

  fetchNameWithAccountNumber = (details: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/verify_bank`,
      details,
      { headers: headers() }
    );
  };

  validateUdyam = (
    udyam_number: string,
    customer_code: string,
    partner_code: string,
    uam_consent: boolean
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/check_valid_udyam`,
      { udyam_number, customer_code, partner_code, uam_consent },
      { headers: headers() }
    );
  };


  validateGstin = (
    gstin_number: string,
    customer_code: string,
    partner_code: string
  ): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/check_valid_gstin`,
      { gstin_number, customer_code, partner_code },
      { headers: headers() }
    );
  };

  updateCustomerProfile = (profile: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.BASE_URL}/${this.partnerSlug}/profiles/me`,
      { sdk_user: profile },
      { headers: headers() }
    );
  };

  fetchPincodeDetails = (pincode: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/pincode/${pincode}`,
      { headers: headers() }
    );
  };

  submitBankDetails = (bankDetails: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.BASE_URL}/${this.partnerSlug}/bank-details/confirm`,
      { sdk_user: bankDetails },
      { headers: headers() }
    );
  };

  camsRedirectUrl = (formData: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/fetch/redirect_url`,
      formData,
      { headers: formDataHeaders() }
    );
  };

  camsWebhook = (loan_uid: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/get/consent_webhook/${loan_uid}`,
      { headers: formDataHeaders() }
    );
  };

  setUpEnach = (enach: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/setup_enach`,
      { enach: enach },
      { headers: headers() }
    );
  };

  updateEnach = (updateEnachRequest: any): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.BASE_URL}/${this.partnerSlug}/update_enach`,
      { enach: updateEnachRequest },
      { headers: headers() }
    );
  };

  getLenders = (loan_uid: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/get_lenders?loan_uid=${loan_uid}`,
      { headers: headers() }
    );
  };

  getLendersTNC = (
    loan_uid: string,
    product_code: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.BASE_URL}/${this.partnerSlug}/tnc_html?loan_uid=${loan_uid}&product_code=${product_code}`,
      { headers: headers() }
    );
  };

  acceptLoanTermsAndCondition = (tnc: any): Promise<AxiosResponse<any>> => {
    return axios.patch(
      `${this.BASE_URL}/${this.partnerSlug}/loans`,
      { loan: tnc },
      { headers: headers() }
    );
  };

  // LOS

  sendOtpLOS = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/v2/getmobileotp/`, payload, {
      headers: ArthAppHeaders(),
    });
  };

  verifyOtpLOS = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.ARTH_APP_URL}/v2/enach/validateotp/`, payload, {
      headers: ArthAppHeaders(),
    });
  };

  fetchLOSCustomerData = (customerID: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/v2/enach/getcustomerdetails/${customerID}/`,
      { headers: ArthAppHeaders() }
    );
  };

  setUpLOSEnach = (enach: any, id: string): Promise<AxiosResponse<any>> => {
    return axios.post(
      `${this.ARTH_APP_URL}/v2/enach/CreateCustomerAndOrderId/${id}/`,
      enach,
      { headers: ArthAppHeaders() }
    );
  };

  updateLOSEnach = (
    paymentId: string,
    customerUCIC: string,
    signature: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ARTH_APP_URL}/v2/enach/gettokenid/${paymentId}/${customerUCIC}/${signature}/`,
      { headers: ArthAppHeaders() }
    );
  };

  fetchProductCodeFromIce = (
    customerId: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.ICE_URL}/v1/get-customer-detail/${customerId}`);
  };

  fetchSoftOfferFromIce = (
    productCode: string
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.ICE_URL}/v1/get-product-detail/${productCode}?is_repeat=False&customer_onboard_form=1`
    );
  };

  fetchUpdateProfileLoginOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_BASE_URL}/v1/request/otp`, payload);
  };

  verifyUpdateProfileLoginOtp = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_BASE_URL}/v1/verify/otp`, payload);
  };

  updateField = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_BASE_URL}/v1/update_change/update`, payload);
  };

  fetchUpdateList = (payload: any, id: any): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.LMS_BASE_URL}/v1/update_change/list?loan_uid=${payload}&id=${id}`
    );
  };

  fetchProfileUpdateIfsc = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.LMS_BASE_URL}/v1/ifsc_master?bank_ifsc_code=${payload}`
    );
  };

  fetchProfileBankDetails = (
    loanUid: any,
    id: any
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.LMS_BASE_URL}/v1/update_change/get_bank_details?loan_uid=${loanUid}&id=${id}`
    );
  };

  verifyProfileBankDetails = (payload: any): Promise<AxiosResponse<any>> => {
    return axios.post(`${this.LMS_BASE_URL}/v1/verify_bank`, payload);
  };

  fetchProfileStateCity = (
    loanUid: any,
    pincode: any
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${this.LMS_BASE_URL}/v1/pincode?pincode=${pincode}&loan_uid=${loanUid}`
    );
  };

  fetchAllLoanDetails(loan_uid) {
    return axios.get(`${this.SDK}/loan-details/${loan_uid}`, {
      headers: pwaHeaders(),
    });
  }

  getAddOns(loan_uid) {
    return axios.get(`${this.SDK}/device-details/${loan_uid}`, {
      headers: pwaHeaders(),
    });
  }

  fetchLoanDocuments(loan_uid) {
    return axios.get(`${this.SDK}/loan-documents/${loan_uid}`, {
      headers: pwaHeaders(),
    });
  }

  getLoanDetails = (): Promise<AxiosResponse<any>> => {
    return axios.get(`${this.BASE_URL}/${this.partnerSlug}/loan-status`, {
      headers: headers(),
    });
  };

  getNewOffer(customerCode, cohort_uid) {
    return axios.get(`${this.SDK}/new-offer/${customerCode}/${cohort_uid}`, {
      headers: headers(),
    });
  }

  fetchPaymentLink = (payload: any) => {
    return axios.post(`${this.SDK}/fetch/payment_link`, payload, {
      headers: pwaHeaders(),
    });
  };
}
