import {
  BlueCircle,
  PinkCircle,
  ZigZagPinkCircle,
  ArthLogo,
  LanguageChange,
  BackButton,
} from "../../../../assets/svg";
import "./loan-summary-header.scss";
import { useNavigate } from "react-router-dom";

const LoanSummaryHeaderPWA = (navigation) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (navigation?.navigation === true) {
      navigate("/arth");
    } else {
      window.history.back();
    }
  };

  return (
    <div>
      <div className="blurrimages" style={{ overflow: "hidden" }}>
        <BlueCircle className="bluecircle" />
        <PinkCircle className="pinkcircle" />
        <ZigZagPinkCircle className="zigzag" />
        <span className="purplecircle"></span>
      </div>
      <div className="blurrdiv">
        <div className="containers">
          <div className="back" onClick={() => goBack()}>
            <BackButton />
          </div>
          <div className="arthlogo">
            <ArthLogo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanSummaryHeaderPWA;
