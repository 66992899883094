import OfferDecision from "../../../components/ice-application/profile/OfferDecision/OfferDecision";
import ActionButtons from "../../../components/ice-application/profile/ActionButtons/ActionButtons";
import BackButton from "../../../components/ice-application/profile/BackButton/BackButton";
import ProfilePicture from "../../../components/ice-application/profile/ProfilePicture/ProfilePicture";
import "./customer-details.scss";
import { Toaster } from "../../../utils/utilities/Toaster";

import React, { useEffect, useState } from "react";
import BasicDetails from "../../../components/ice-application/profile/DetailsBox/BasicDetails";
import MainDetails from "../../../components/ice-application/profile/MainDetails/MainDetails";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../../../components/ice-application/navbar/NavbarComponent";
import Loader from "../../../components/sales-application/Loader/Loader";
import axios from "axios";
import greenIcons from "../../../../src/assets/images/green-dot.png";
import redIcons from "../../../../src/assets/images/red-dot.png";
import blueIcons from "../../../../src/assets/images/blue-dot.png";
import yellowIcons from "../../../../src/assets/images/yellow-dot.png";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

function CustomerDetails() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("ICELOGGEDIN")) {
      navigate("/ice/");
    }
  }, []);
  const [userData, setUserData] = useState();
  const [tenure, setTenure] = useState();
  const toaster = new Toaster();
  const [amount, setAmount] = useState(0);
  const [riskDetails, setRiskDetails] = useState(null);
  const [actionTaken, setActionTaken] = useState();
  const [emi, setEmi] = useState();
  const [interestRate, setInterestRate] = useState();
  const [processingFee, setProcessingFee] = useState();
  const [makerData, setMakerData] = useState();

  const [bureauType, setBureauType] = useState("customer");

  const [productCode, setProductCode] = useState();
  const [productType, setProductType] = useState("");
  const [productList, setProductList] = useState([]);
  const [iceStatus, setIceStatus] = useState("");
  const [tenurelist, setTenureList] = useState([]);
  const [offerStatus, setOfferStatus] = useState();

  const [loader, setLoader] = useState(false);

  const [report, setReport] = useState();
  const [additionalData, setAdditional] = useState();

  useEffect(() => {
    if (userData) {
      getReport();
    }
  }, [bureauType]);

  const getReport = async () => {
    setReport("Not Found");
    const queryParams = new URLSearchParams(window.location.search);

    const flag = bureauType === "customer" ? "false" : "true";
    const bureau_url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/get-bureau-details/" +
        queryParams.get("id") +
        "/" +
        flag
    );
    const customer_url = new URL(
      process.env.REACT_APP_ICE_BASE_URL +
        "/fetch-customer-bureau/" +
        queryParams.get("id")
    );
    const response = await fetch(
      bureauType === "customer" ? customer_url : bureau_url
    );
    if (response.status === 200) {
      const respData = await response.json();
      const dataToSet = respData?.data?.html_response;
      setAdditional(respData?.data)
      setReport(dataToSet);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProductList();
    fetchDataRiskDetailsCustomerDetails();
  }, [actionTaken]);

  const fetchData = async () => {
    await getReport();
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get("id");
    const requestOptions = {
      headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
    };
    let url = new URL(
      process.env.REACT_APP_ICE_BASE_URL + "/customer-profile-view/" + value
    );
    try {
      setLoader(true);
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setOfferStatus(data?.basic_details?.offer_status);
      setTenureList(data?.ice_status === "Accepted" ? data?.offered_details?.tenure_limit : data?.basic_details?.tenure_limit);
      setUserData(data);
      setTenure(
        parseInt(
          data?.offered_details?.offered_tenure ??
            data?.basic_details?.default_tenure
        )
      );
      setAmount(
        data?.offered_details?.offered_amount ??
          data?.basic_details?.default_amount
      );
      setInterestRate(
        data?.offered_details?.monthly_interest ??
          data?.basic_details?.monthly_interest
      );
      setProcessingFee(
        data?.offered_details?.processing_fees ??
          data?.basic_details?.default_Processing_Fee
      );
      setEmi(data?.offered_details?.emi ?? data?.basic_details?.default_emi);
      setProductCode(
        data?.offered_details?.offered_product_code ?? data?.product_code
      );

      setProductType(data?.basic_details?.product ?? "");
      setIceStatus(data?.ice_status);
      datamakers(data);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error - ", error);
    }
  };

  const fetchProductList = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get("id");
    const requestOptions = {
      headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
    };
    let url = new URL(
      process.env.REACT_APP_ICE_BASE_URL + "/product-type-change/" + value
    );
    try {
      setLoader(true);
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data?.status === "success") {
        setProductList(data?.data);
      }
    } catch (error) {
      setLoader(false);
      console.log("error - ", error);
    }
  };
  const handleProductType = async (key, value) => {
    setProductType(value);
    if (value && value !== "Select") {
      const queryParams = new URLSearchParams(window.location.search);
      const valueid = queryParams.get("id");
      const requestOptions = {
        method: "POST",
        headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
      };
      let url = new URL(
        process.env.REACT_APP_ICE_BASE_URL +
          "/product-type-change/" +
          valueid +
          "/" +
          value
      );
      try {
        //setLoader(true)
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        if (data?.status === "success") {
          fetchData();
          fetchDataRiskDetailsCustomerDetails();
          toaster.show(false, data?.message);
        }
        //setLoader(false);
      } catch (error) {
        //setLoader(false)
        console.log("error - ", error);
      }
    }
  };

  const fetchDataRiskDetailsCustomerDetails = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get("id");
    const requestOptions = {
      headers: { Authorization: process.env.REACT_APP_ICE_AUTHORIZATION },
    };
    let url = new URL(
      process.env.REACT_APP_ICE_BASE_URL + "/correlation-data/" + value
    );
    try {
      setLoader(true);
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setRiskDetails(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log("error - ", error);
    }
  };
  const datamakers = async (userData) => {
    let data = [];

    // Check if business address is provided
    if (userData?.business_details?.basic_details?.business_address) {
      const apiKey = "AIzaSyAFYHAEWUauYGusPSnWuRWCczjXbhseTPg"; // Replace with your API key
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        userData.business_details.basic_details.business_address
      )}&key=${apiKey}`;

      try {
        const response = await axios.get(url);
        const result = response.data.results[0];
        //  console.log("gg", response,result)
        if (result) {
          const lat = result.geometry.location.lat;
          const lng = result.geometry.location.lng;
          // const dataaddress = await fetchAddress(
          //   result.geometry.location.lat,
          //   result.geometry.location.lng
          // );
          // console.log("gg", dataaddress)
          data.push({
            id: 1,
            position: { lat, lng },
            title: "Business Address",
            address: userData.business_details.basic_details.business_address,
            icons: blueIcons, // Ensure this is defined and imported
          });
          // const shopPoints = extractCoordinates( userData.shop_location);
          // const dataaddress = await fetchAddress(
          //   shopPoints?.lat,
          //   shopPoints?.lng
          // );
          // console.log("gg", dataaddress)
          // Additional marker with coordinates from shop_location
          // data.push({
          //   id: 2,
          //   position: extractCoordinates(
          //     userData.shop_location ?? "SRID=4326;POINT (0 0)"
          //   ),
          //   title:'Business Address (from Lat Long)',
          //   address: dataaddress,
          //   icons: greenIcons, // Ensure this is defined and imported
          // });
        }
      } catch (err) {
        // console.error('Error fetching address data:', err);
        // Handle error, e.g., setError('An error occurred');
      }
    }
    const shopPoints = extractCoordinates(userData.shop_location);
    const dataaddress = await fetchAddress(shopPoints?.lat, shopPoints?.lng);
    if (shopPoints) {
      data.push({
        id: 2,
        position: extractCoordinates(
          userData.shop_location ?? "SRID=4326;POINT (0 0)"
        ),
        title: "Business Address (from Lat Long)",
        address: dataaddress,
        icons: greenIcons, // Ensure this is defined and imported
      });
    }
    // Check if branch location data is available
    if (userData?.branch_location?.latitude) {
      data.push({
        id: 3,
        position: {
          lat: userData.branch_location.latitude,
          lng: userData.branch_location.longitude,
        },
        title: "Center Address",
        address: userData.branch_location.center,
        icons: redIcons, // Ensure this is defined and imported
      });
    }
    if (userData?.nearest_branch) {
      data.push({
        id: 4,
        position: {
          lat: userData.nearest_branch.latitude,
          lng: userData.nearest_branch.longitude,
        },
        title: "Nearest Center Address",
        address: userData.nearest_branch.branch_name,
        icons: yellowIcons, // Ensure this is defined and imported
      });
    }
    setMakerData(data); // Return the data for further processing or setting state
  };

  const fetchAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            latlng: `${latitude},${longitude}`,
            key: "AIzaSyAFYHAEWUauYGusPSnWuRWCczjXbhseTPg",
          },
        }
      );

      if (response.data.status === "OK") {
        const results = response.data.results[0];
        if (response.data.results.length > 0) {
          return results.formatted_address;
        }
      }
    } catch (err) {
      // setError('An error occurred');
      // console.error(err);
    }
  };

  function extractCoordinates(wkt) {
    // Regular expression to match the POINT coordinates
    const regex = /POINT \(([^ ]+) ([^ ]+)\)/;
    const match = wkt.match(regex);

    if (match) {
      const lng = parseFloat(match[1]);
      const lat = parseFloat(match[2]);
      return { lat, lng };
    }
  }

  if (userData && localStorage.getItem("ICELOGGEDIN") && loader === false) {
    return (
      <>
        <NavbarComponent active={"Customers"} />
        <div
          style={{
            backgroundColor: "#f5f6fa",
            padding: "40px",
            justifyContent: "center",
            marginTop: "80px",
          }}
        >
          <div>
            <div>
              <div className="row">
                <div className="col" style={{ display: "flex" }}>
                  <div onClick={() => navigate(-1)}>
                    <div className="back-button">
                      <ArrowBackIosNewOutlinedIcon
                        color="#707070"
                        sx={{ height: "20px", width: "14px" }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <span
                        className="back-comps"
                        style={{ color: "#B0B0B0", marginLeft: "20px" }}
                      >
                        Customers /
                      </span>
                      <span className="back-comps" style={{ color: "#3E3E3E" }}>
                        {userData?.customer_details?.Demographics?.name}
                      </span>
                    </div>
                  </div>
                  {(userData?.ice_status === "In Progress" ||
                    userData?.ice_status === "On Hold") &&
                    offerStatus != "not_offered" && (
                      <div
                        className="col"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <ActionButtons
                          status={userData?.ice_status}
                          setAction={setActionTaken}
                          userData={userData}
                          productCode={productCode}
                          amount={amount}
                          tenure={tenure}
                          interestRate={interestRate}
                          processingFee={processingFee}
                          emi={emi}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="middle-container">
              <div class="row">
                <div class="col-3">
                  <ProfilePicture
                    imageUrl={userData?.shop_owner_photo}
                    userName={userData?.customer_details?.Demographics?.name}
                    status={userData?.ice_status}
                  />
                </div>
                <div class="col-9" style={{ backgroundColor: "#EDF9F9" }}>
                  <OfferDecision
                    tenure={tenure}
                    offerStatus={offerStatus}
                    amount={amount}
                    setTenure={setTenure}
                    tenurelist={tenurelist}
                    setAmount={setAmount}
                    riskScore={userData?.risk_scale}
                    setProductCode={setProductCode}
                    userData={userData}
                    emi={emi}
                    setEmi={setEmi}
                    processingFee={processingFee}
                    setProcessingFee={setProcessingFee}
                    interestRate={interestRate}
                    setInterestRate={setInterestRate}
                  />
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "40px" }}>
              <div class="row" style={{ marginTop: "20px" }}>
                <div class="col-3">
                  <BasicDetails
                    basicData={userData.basic_details}
                    iceStatus={iceStatus}
                    productList={productList}
                    handleProductType={handleProductType}
                    productType={productType}
                    additionalData={additionalData}
                  />
                </div>

                <div class="col-md">
                  <MainDetails
                    userData={userData}
                    makerData={makerData}
                    report={report}
                    setBureauType={setBureauType}
                    riskDetails={riskDetails}
                    additionalData={additionalData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="loaderStyle">
        <Loader />
      </div>
    );
  }
}

export default CustomerDetails;
