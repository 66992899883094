import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN_UNNATI, {}, "arth_unnati");

let env_check = process.env.REACT_APP_IS_PROD === "true";

let actions = {
  identify: (id) => {
    if (env_check) {
      if (true) {
        mixpanel.arth_unnati.identify(id);
      }
    }
  },
  alias: (id) => {
    if (env_check) {
      if (true) {
        mixpanel.arth_unnati.alias(id);
      }
    }
  },
  track: (name, props) => {
    if (env_check) {
      if (true) {
        mixpanel.arth_unnati.track(name, props);
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        if (true) {
          mixpanel.arth_unnati.people.set(props);
        }
      }
    },
  },
};

export let Mixpanel = actions;
