import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import Gauge from "../Gauge/Gauge";
import "./bureau-details.scss";

function BureauDetails({ bureauData, report, bureauHistory, activeTab }) {
  const nf = new Intl.NumberFormat();
  const finalData = {
    "No. of Active Accounts": bureauData?.no_of_active_accounts
      ? bureauData?.no_of_active_accounts
      : 0,
    "Total Balance Amount": bureauData?.total_balance_amount
      ? nf.format(bureauData?.total_balance_amount)
      : 0,
    "Total Monthly Payment": bureauData?.total_monthly_payment_amount
      ? nf.format(bureauData?.total_monthly_payment_amount)
      : 0,
    "No. of Write Offs": bureauData?.no_of_write_offs
      ? bureauData?.no_of_write_offs
      : 0,
    "Accounts Delinquent": bureauData?.amount_delinquent
      ? nf.format(bureauData?.amount_delinquent)
      : 0,
    "Total Enquiry": bureauData?.equity_count
      ? nf.format(bureauData?.equity_count)
      : 0,
  };

  
  return (
    <div className="sub-outer-box">
      <div className="bureau-container">
        <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
          <div class="row">
            <div class="col-md">
              <span className="hdr-style">Bureau Details</span>
            </div>
            {activeTab === "customer" && bureauData && (
              <div class="col-md-auto">
                <OverlayTrigger
                  rootClose={true}
                  trigger="click"
                  key="history"
                  placement="bottom"
                  style={{ marginRight: "300px" }}
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <div style={{ width: "200px" }}>
                        <div
                          style={{
                            borderBottom: "solid",
                            borderWidth: "1px",
                            borderColor: "#B0B0B0",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          <span className="history-header-txt">History</span>
                        </div>

                        <div
                          style={{ paddingInline: "10px", paddingTop: "10px" }}
                        >
                          {bureauHistory?.map((key) => (
                            <div style={{ marginBottom: "10px" }}>
                              <div style={{ display: "flex" }}>
                                <div className="history-col-txt">Date</div>
                                <div
                                  className="history-col-txt"
                                  style={{ marginLeft: "auto" }}
                                >
                                  Bureau Score
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  borderBottom: "solid",
                                  borderWidth: "1px",
                                  borderColor: "#B0B0B0",
                                }}
                              >
                                <div className="history-value-one">
                                  {key.bureau_fetched_at}
                                </div>
                                <div className="history-value-two">
                                  {key.credit_score}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover>
                  }
                >
                  
                  <span className="pink-txt-btns">History</span>
                </OverlayTrigger>
              </div>
            )}

            <div class="col-md-auto">
              {/* <div
                className="sub-outer-box"
                onClick={() => setRefresh(refresh + 1)}
              >
                <i class="bi bi-arrow-clockwise fa-2x"></i>
              </div> */}
            </div>
          </div>
          <div
            class="col-md"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Gauge bureauData={bureauData} />
          </div>
          <div style={{ display: "flex" }}>
            <Col />
            {() => {
              if (bureauData.credit_score >= 640) {
                return (
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    Good
                  </Col>
                );
              } else if (bureauData.credit_score < 266) {
                return (
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    Bad
                  </Col>
                );
              } else {
                return (
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    Average
                  </Col>
                );
              }
            }}

            <Col
              className="pink-txt-btns"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div
                onClick={() => {
                  const newWin = window.open();
                  newWin.document.write(
                    report
                  );
                }}
              >
                View Report
              </div>
            </Col>
          </div>
        </div>
        <div className="bureau-detail-container">
          {Object.keys(finalData).map((key) => (
            <div class="row" style={{ paddingTop: "10px" }}>
              <div class="col-md basic-left-body">{key}</div>
              <div class={`col-md basic-right-body`}>{finalData[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BureauDetails;
